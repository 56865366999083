import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  components,
  MultiValueProps,
  MenuListProps,
  OptionProps,
} from 'react-select'
import { FaCheck, FaList } from 'react-icons/fa'
import {
  HoverDivider,
  StyledCustomMultiValueHoverContainer,
  LockIcon,
  StyledCustomMultiValueContainer,
  StyledMenuTabContainer,
  StyledMenuTab,
  StyledCustomOptionContainer,
  StyledCustomOptionFormulaContainer,
  StyledCustomOptionFormulaLabel,
  StyledCustomOptionFormulaDescription,
  StyledCustomOptionSourceContainer,
  StyledCustomOptionSourceLabel,
  StyledCustomOptionPicklistLabel,
  StyledCustomMultiValueRemoveContainer,
  RemoveIcon,
  HoverRemoveIcon,
  HoverEditIcon,
  StyledCustomMultiValueLabel,
} from '@components/Form/MultiCreatableCustom/styled'
import {
  TabType,
  SelectValueMulti,
  CustomCreatableAdditionalProps,
} from '@components/Form/MultiCreatableCustom'
import { useTranslation } from 'react-i18next'
import { AiOutlineFunction } from 'react-icons/ai'
import { MdTextFields } from 'react-icons/md'
import { MappedFieldType } from 'Nbee'
import ReactTooltip from 'react-tooltip'

export const CustomMultiValue = ({
  children,
  ...props
}: MultiValueProps): ReactElement<MultiValueProps> | null => {
  const data = props.data as SelectValueMulti
  const isFormula = data.fieldType === 'formula'
  const [isHovering, setIsHovering] = useState(false)
  const valueContainerRef = useRef<HTMLDivElement>(null)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const handleEditClick = () => {
    const data = props.data as SelectValueMulti
    props.setValue(
      { ...data, isNewFormula: false }, // we pass isNewFormula to the option to indicate that it's not a new formula
      'select-option',
      props.options
    )
  }
  const handleRemoveClick = () => {
    props.setValue(props.data, 'deselect-option', props.options)
  }
  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  const { sourceLogoComponent } =
    props.selectProps as CustomCreatableAdditionalProps // another extra prop

  // get the size of the value container to use it for the hover container
  useEffect(() => {
    if (valueContainerRef.current) {
      const { width, height } =
        valueContainerRef.current.getBoundingClientRect()
      setContainerSize({ width, height })
    }
  }, [])

  const shouldShowTooltip =
    data.label &&
    (data.label[0] === ' ' ||
      data.label[data.value.length - 1] === ' ' ||
      data.label.length > 20) // 20 is an approximate number of characters that can fit

  const renderTooltipContent = (text: string) => {
    if (!text) return null

    const chars = text.split('')
    return (
      <div>
        {chars.map((char, index) => {
          const isFirst = index === 0
          const isLast = index === chars.length - 1
          const shouldHighlight = (isFirst || isLast) && char === ' '
          return shouldHighlight ? (
            <span key={index} style={{ backgroundColor: '#40b1f3' }}>
              &nbsp;
            </span>
          ) : (
            <span key={index}>{char}</span>
          )
        })}
      </div>
    )
  }

  return (
    <components.MultiValue {...props}>
      <components.MultiValueContainer {...props}>
        <StyledCustomMultiValueContainer
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          fieldType={data.fieldType as MappedFieldType}
        >
          {shouldShowTooltip ? (
            <ReactTooltip
              id={`multi-creatable-tooltip-${data.value}`}
              place='top'
              type='dark'
              effect='solid'
              getContent={() => renderTooltipContent(data.label)}
            />
          ) : null}
          {!props.isDisabled && isHovering && isFormula ? (
            <StyledCustomMultiValueHoverContainer
              width={containerSize.width}
              height={containerSize.height}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }} // prevents menu to opening when clicking on the pencil icon
            >
              <HoverEditIcon onClick={handleEditClick} />
              <HoverDivider />
              <HoverRemoveIcon onClick={handleRemoveClick} />
            </StyledCustomMultiValueHoverContainer>
          ) : (
            <StyledCustomMultiValueLabel
              label={props.selectProps.getOptionLabel(data) || ''}
              ref={valueContainerRef}
              data-tip
              data-for={`multi-creatable-tooltip-${data.value}`}
            >
              {isFormula && <AiOutlineFunction size={16} />}
              {data.fieldType === 'source' &&
                sourceLogoComponent?.props.src &&
                sourceLogoComponent}
              {/* Display logo only we have it */}
              {data.fieldType === 'text' && <MdTextFields size={16} />}
              {data.fieldType === 'custom' && <FaList size={11} />}
              <components.MultiValueLabel {...props}>
                {children}
              </components.MultiValueLabel>
            </StyledCustomMultiValueLabel>
          )}
          {!props.isDisabled && !isFormula && (
            <components.MultiValueRemove {...props}>
              <StyledCustomMultiValueRemoveContainer
                onMouseDown={(e) => e.stopPropagation()}
              >
                <RemoveIcon
                  onClick={handleRemoveClick}
                  className={'remove-icon'}
                />
              </StyledCustomMultiValueRemoveContainer>
            </components.MultiValueRemove>
          )}
        </StyledCustomMultiValueContainer>
      </components.MultiValueContainer>
    </components.MultiValue>
  )
}

export const CustomMenuList = ({
  children,
  ...props
}: MenuListProps): ReactElement<MultiValueProps> | null => {
  const {
    activeTab,
    handleTabChange,
    userHasFormulaEnabled,
    hasPicklist,
    hasFormula,
  } = props.selectProps as CustomCreatableAdditionalProps // these are the extraProps added to the MultiCreatableCustom component
  if (!handleTabChange) return null
  const handleTabClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tab: TabType
  ) => {
    e.preventDefault()
    handleTabChange(tab)
  }

  return (
    <components.MenuList {...props}>
      {(hasPicklist || hasFormula) && (
        <StyledMenuTabContainer>
          {hasPicklist && (
            <StyledMenuTab
              isActive={activeTab === 'custom'}
              onClick={(e) => handleTabClick(e, 'custom')}
            >
              Picklist
            </StyledMenuTab>
          )}
          <StyledMenuTab
            isActive={activeTab === 'fields'}
            onClick={(e) => handleTabClick(e, 'fields')}
          >
            Fields
          </StyledMenuTab>
          {hasFormula && (
            <StyledMenuTab
              isActive={activeTab === 'formulas'}
              onClick={(e) => handleTabClick(e, 'formulas')}
              style={{
                color:
                  activeTab === 'formulas' && userHasFormulaEnabled
                    ? 'black'
                    : '#A9A9A9',
              }}
            >
              Formulas
              {!userHasFormulaEnabled && <LockIcon />}
            </StyledMenuTab>
          )}
        </StyledMenuTabContainer>
      )}
      {children}
    </components.MenuList>
  )
}

export const CustomOption = (
  props: OptionProps
): ReactElement<MultiValueProps> | null => {
  const { t } = useTranslation()
  const data = props.data as SelectValueMulti
  const isFormula = data.fieldType === 'formula'

  const { userHasFormulaEnabled, onRedirectToPricing } =
    props.selectProps as CustomCreatableAdditionalProps // these are the extraProps added to the MultiCreatableCustom component
  const handleRedirectToPricing = () => {
    if (onRedirectToPricing) {
      onRedirectToPricing(!userHasFormulaEnabled)
    }
  }
  return (
    <components.Option {...props}>
      <StyledCustomOptionContainer isFocused={props.isFocused}>
        {isFormula ? (
          <StyledCustomOptionFormulaContainer onClick={handleRedirectToPricing}>
            <StyledCustomOptionFormulaLabel>
              {data.label}
            </StyledCustomOptionFormulaLabel>
            <StyledCustomOptionFormulaDescription>
              {data.formulaDescription}
            </StyledCustomOptionFormulaDescription>
          </StyledCustomOptionFormulaContainer>
        ) : (
          <StyledCustomOptionSourceContainer>
            <StyledCustomOptionSourceLabel>
              {data.label}
            </StyledCustomOptionSourceLabel>
            {data?.fieldType === 'custom' ? (
              <StyledCustomOptionPicklistLabel>
                {data.value}
              </StyledCustomOptionPicklistLabel>
            ) : (
              data?.fieldType === 'source' &&
              data?.lastValue !== undefined && (
                <StyledCustomOptionPicklistLabel>
                  {data.lastValue}
                </StyledCustomOptionPicklistLabel>
              )
            )}
          </StyledCustomOptionSourceContainer>
        )}
      </StyledCustomOptionContainer>
    </components.Option>
  )
}
